const UpdateCustomerPhoneNumber = `
  mutation UpdateCustomerPhoneNumber($phone_number: String!) {
    updateCustomerPhoneNumber(phone_number: $phone_number) {
      id
      phone_number
    }
  }
`;

const SigninWithPhoneNumber = `
  mutation SigninWithPhoneNumber($phone_number: String!,$password: String!) {
    signinWithPhoneNumber(phone_number: $phone_number,password:$password) {
     token 
    }
  }
`;

export {
  UpdateCustomerPhoneNumber, SigninWithPhoneNumber
};
